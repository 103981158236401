import { useTranslation } from "gatsby-plugin-react-i18next";
import * as Yup from "yup";

import { countries, CountryCode, PhoneNumberLengthValidation } from "../../../settings/countries";
import { getPhoneNumberLength } from "../../../utils/locale-configuration-utils";
import { FormikConfig } from "../../utils/formik-types";
import { sanitizePhone } from "../../utils/sanitizers";

interface UsePhoneNumberParams {
  defaultCountryCode: string;
  country: CountryCode;
  isWhatsAppNumber?: boolean;
  isPhoneNumberRequired?: boolean;
}

export interface UsePhoneNumberFormValues {
  countryCode: string;
  phone: string;
}

export interface UsePhoneNumber extends FormikConfig<UsePhoneNumberFormValues> {
  phoneNumberLength: PhoneNumberLengthValidation;
}

export const usePhoneNumber = ({
  defaultCountryCode,
  country,
  isWhatsAppNumber = false,
  isPhoneNumberRequired = true,
}: UsePhoneNumberParams): UsePhoneNumber => {
  const { t } = useTranslation();
  const phoneNumberLength = getPhoneNumberLength(country);

  const initialValues: UsePhoneNumberFormValues = {
    countryCode: defaultCountryCode,
    phone: "",
  };

  const isValidWhatsAppNumber = (phone: string): boolean => {
    const isInvalid = countries[country].invalidStartingNumbersForWhatsApp.some((invalidStart) =>
      sanitizePhone(phone, country).startsWith(invalidStart)
    );
    const isException = countries[country].exceptionStartingNumbersForWhatsApp.some((exception) =>
      sanitizePhone(phone, country).startsWith(exception)
    );

    return !(isInvalid && !isException);
  };

  const validationSchema = Yup.object({
    countryCode: Yup.string()
      .trim()
      .test({
        name: "validate-country-code",
        test(value, ctx) {
          const { phone } = ctx.parent as UsePhoneNumberFormValues;

          if ((!isWhatsAppNumber || phone || isPhoneNumberRequired) && !value) {
            return ctx.createError({ message: t("common.validation.required") });
          }

          return true;
        },
      }),
    phone: Yup.string()
      .min(phoneNumberLength.min, t("common.validation.min", { minLength: phoneNumberLength.min }))
      .max(phoneNumberLength.max, t("common.validation.max", { maxLength: phoneNumberLength.max }))
      .trim()
      .test({
        name: "validate-phone",
        test(value, ctx) {
          if (isPhoneNumberRequired && !value) {
            return ctx.createError({ message: t("common.validation.required") });
          }

          if (isWhatsAppNumber && value && !isValidWhatsAppNumber(value)) {
            return ctx.createError({ message: t("common.validation.invalid_whatsapp_number") });
          }

          return true;
        },
      }),
  });

  return {
    initialValues,
    validationSchema,
    phoneNumberLength,
  };
};
