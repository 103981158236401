import cn from "classnames";
import { Form, Formik, FormikHelpers } from "formik";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, ReactNode, useRef, useState } from "react";

import Button from "../../../../../atoms/button/Button";
import Heading from "../../../../../atoms/heading/Heading";
import TextFormControl from "../../../../../atoms/text-form-control/TextFormControl";
import {
  usePhoneNumber,
  UsePhoneNumberFormValues,
} from "../../../../../core/domain/hooks/usePhoneNumber";
import useOnce from "../../../../../core/utils/hooks/useOnce";
import { sanitizePhone } from "../../../../../core/utils/sanitizers";
import CheckList from "../../../../../molecules/check-list/CheckList";
import { Result } from "../../../../../organisms/result/domain/result";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../../../settings/countries";
import { ProductId } from "../../../../../settings/products";
import { Events, track } from "../../../../../utils/analytics";
import { experiments, isExperimentDefaultVariant } from "../../../../../utils/experiments";
import {
  getPhoneNumberPrefix,
  isPreSalesModeAvailable,
} from "../../../../../utils/locale-configuration-utils";
import DiscountBadge, { DiscountBadgeVariants } from "../../atoms/discount-badge/DiscountBadge";
import BarkibuCardBanner from "../../organisms/barkibu-card-banner/BarkibuCardBanner";
import GlAddon from "../../organisms/gl-addon/GlAddon";
import { HealthProductElement } from "../../organisms/health-product-selector/HealthProductSelector";
import * as styles from "./HealthProductCard.module.scss";

interface HealthProductCardProps {
  healthProduct: HealthProductElement;
  isDisabled: boolean;
  isExpanded: boolean;
  handleExpanded: () => void;
  discountedPrice?: ReactNode;
  appliedPromotionCode?: string;
}

const HealthProductCard = ({
  healthProduct,
  isExpanded,
  handleExpanded,
  discountedPrice,
  appliedPromotionCode,
}: HealthProductCardProps): ReactElement => {
  const result: Result | null = useResult();
  const preSalesMode = isPreSalesModeAvailable(result?.country as CountryCode);
  const { image, name, features, price, benefits } = healthProduct;
  const { t } = useTranslation();
  const ref = useRef(null);
  const phoneNumberPrefix = getPhoneNumberPrefix(result?.country as CountryCode);
  const {
    initialValues,
    validationSchema,
    phoneNumberLength: { min: phoneMinLength, max: phoneMaxLength },
  } = usePhoneNumber({
    defaultCountryCode: phoneNumberPrefix,
    country: result?.country as CountryCode,
    isWhatsAppNumber: true,
    isPhoneNumberRequired: false,
  });
  const [isPhoneSubmitted, setIsPhoneSubmitted] = useState(false);

  const toggle = (): void => {
    const properties = { block: ProductId.healthBasic };
    const eventName = !isExpanded ? Events.BLOCK_EXPANDED : Events.BLOCK_COLLAPSED;

    handleExpanded();
    track(eventName, properties);
  };

  const handleStartTyping = useOnce(() => {
    track(Events.START_TYPING_ON_FIELD, {
      eventSender: "quotes_page-pre_sales_message-insurance_doubts",
    });
  });

  return (
    <div ref={ref} className={cn(styles.healthProductCard)}>
      <div className={styles.imageWrapper}>{image}</div>
      <Heading level={5} adoptionClassName={styles.name}>
        {name}
      </Heading>
      {!preSalesMode && <span className={cn(styles.price)}>{price}</span>}
      {discountedPrice && appliedPromotionCode && (
        <section className={styles.discountedPriceWrapper}>
          <div>
            <span className={styles.discountedPrice}>{discountedPrice}</span>
            <span className={styles.discountedPriceMessage}>
              {t("quotes.cart.summary.becas_discount.message")}
            </span>
          </div>
          <DiscountBadge
            discountName={appliedPromotionCode}
            adoptionClassName={styles.discountBadge}
            variantName={DiscountBadgeVariants.alternate}
          />
        </section>
      )}
      <CheckList
        id={`${ProductId.healthBasic}_features`}
        items={features}
        adoptionClassName={styles.featuresCheckList}
      />
      <div
        id={ProductId.healthBasic}
        className={cn(styles.benefitsWrapper, { [styles.isExpanded]: isExpanded })}
      >
        {benefits.map(
          (benefit, index) =>
            benefit.items && (
              <>
                {benefit.type === "gl-addon" ? (
                  <GlAddon benefit={benefit} />
                ) : (
                  <div key={index}>
                    <Heading level={6} adoptionClassName={styles.benefitTitle}>
                      {benefit.title}
                    </Heading>
                    <CheckList
                      key={index}
                      id={`${ProductId.healthBasic}-benefits`}
                      items={benefit.items}
                    />
                  </div>
                )}
              </>
            )
        )}
      </div>
      <Button
        type={"button"}
        className={cn(styles.expandableButton)}
        onClick={toggle}
        aria-controls={ProductId.healthBasic}
        aria-expanded={isExpanded}
      >
        {isExpanded ? t("common.see_less") : t("common.see_more")}
      </Button>
      {preSalesMode && (
        <Formik
          initialValues={initialValues}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(
            values: UsePhoneNumberFormValues,
            { setSubmitting }: FormikHelpers<UsePhoneNumberFormValues>
          ): void => {
            if (!values.phone) {
              return;
            }

            setSubmitting(true);

            const sanitizedPhone = sanitizePhone(values.phone, result?.country as CountryCode);
            const fullPhoneNumber = `+${values.countryCode}${sanitizedPhone}`;

            track(Events.PHONE_SUBMITTED, {
              eventSender: "quotes_page-pre_sales_message-insurance_doubts",
              preQuotesPhoneNumber: fullPhoneNumber,
              petName: result?.pet_name,
            });

            setTimeout(() => {
              setIsPhoneSubmitted(true);
              setSubmitting(false);
            }, 2000);
          }}
        >
          {({ isSubmitting, setFieldValue, isValid, values }) => (
            <Form>
              <div className={styles.preSalesMessageWrapper}>
                <svg className={cn("icon", `icon--whatsapp`, styles.icon)} role="img">
                  <use href={`#icon-whatsapp`}></use>
                </svg>
                <Heading level={6} adoptionClassName={styles.title}>
                  {t("pg_quotes.pre_sales_message.title")}
                </Heading>
                <p>{t("pg_quotes.pre_sales_message.form_description")}</p>
                <div className={styles.phoneNumberFieldsWrapper}>
                  <TextFormControl
                    adoptionClassName={styles.phoneNumberPrefix}
                    name="countryCode"
                    label={t("common.phone_number.country_code.field.title")}
                    type="tel"
                    maxLength={4}
                    onChange={(e) =>
                      setFieldValue("countryCode", e.target.value.replace(/\D/g, ""))
                    }
                  />
                  <TextFormControl
                    name="phone"
                    label={t("common.whatsapp_phone_number.field.title")}
                    type="tel"
                    minLength={phoneMinLength}
                    maxLength={phoneMaxLength}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue("phone", value.replace(/\D/g, ""));
                      if (value.length > 0) {
                        handleStartTyping();
                      }
                    }}
                  />
                </div>
                <Button
                  type="submit"
                  disabled={
                    isPhoneSubmitted || isSubmitting || !isValid || values.phone.length === 0
                  }
                  isLoading={isSubmitting}
                  adoptionClassName={styles.submitButton}
                >
                  {isPhoneSubmitted ? t("common.talk_soon") : t("common.save")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {isExperimentDefaultVariant(experiments.barkibuCardOnQuotes) && !preSalesMode && (
        <BarkibuCardBanner adoptionClassName={styles.barkibuCardBanner} />
      )}
    </div>
  );
};

export const query = graphql`
  fragment ProductFeaturesFragment on ContentfulProductFeaturesList {
    entryTitle
    features
    locale: node_locale
  }
`;

export default HealthProductCard;
